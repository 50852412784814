import { useState, useEffect } from "react";
import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import LoadingAndErrIndicator from "../general/status/LoadingAndErrorIndicator";
import moment from "moment";
import { Link } from 'react-router-dom';

const JOB_URL = '/jobs';
const JobInfo = ({ jobId }) => {
    const { auth } = useAuth();
    const [jobInfo, setJobInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [err, setErr] = useState(null);

    useEffect(() => {
        getJob();
    }, []);

    const getJob = async () => {
        setIsLoading(true);
        const URL = `${JOB_URL}/${jobId}`;
        try {
            const { data } = await axios.get(
                URL,
                {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
            setJobInfo(data);
        } catch (err) {
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (<div className="card">
        <div className="card-body">
            <h5 className="card-subtitle mb-2">JOB</h5>
            {err || isLoading ? (<LoadingAndErrIndicator err={err} isLoading={isLoading} loadingMessage={"Loading job data..."}/>) : (<div>
                <h3 className="card-title">{jobInfo?.job?.name ?? "unknown job name"}</h3>
                <a style={{float: "left", marginRight: "3px"}}>Creator:</a>
                <Link to={"../users/" + jobInfo?.creator.id}>
                    <a className="nav-link" href="#" style={{float: "left"}}>
                        {jobInfo?.creator.email ?? "-"}
                    </a>
                </Link><br></br>
                <small className="text-muted">{jobInfo?.job?.createdAt ? ("created at: " + moment(jobInfo.job.createdAt).format('D.M.YYYY')) : ""}</small>
            </div>)}
        </div>
    </div>)
}


export default JobInfo;