import { useContext, useState, useEffect, useRef } from "react";
import { Link, useParams } from 'react-router-dom';
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import NewProductModal from "../NewProductModal"
import Loading from "../general/status/Loading";
import AssignLabModal from "../products/product/lab/AssignLabModal";
import moment from "moment";
import NewMaterialModal from "./NewMaterialModal";

const MATERIALS_URL = '/admin/materials';
const REMOVE_MATERIALS_URL = '/admin/materials/remove';
const NEW_MATERIAL_URL = '/admin/materials/add';

const CATEGORIES_URL = '/admin/materials/categories';
const REMOVE_CATEGORIES_URL = '/admin/materials/categories/remove';
const NEW_CATEGORY_URL = '/admin/materials/categories/add';

const Materials = () => {
    const { auth } = useAuth();

    const [materialData, setMaterialData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [err, setErr] = useState('');
    const [selectAllMaterials, setSelectAllMaterials] = useState(false);
    const [selectedMaterials, setSelectedMaterials] = useState([]);
    const [selectedAllCategories, setSelectedAllCategories] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [isMaterialModalOpen, setIsMaterialModalOpen] = useState(false);
    const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const tabs = [
        { index: 0, name: "Environment" },
        { index: 1, name: "Waste Treatment" },
        { index: 2, name: "Multi-use" },
    ];


    const openNewMaterialModal = () => {
        setIsMaterialModalOpen(true);
    };

    const handleMaterialModalClose = () => {
        setIsMaterialModalOpen(false);
    };

    const openNewCategoryModal = () => {
        setIsCategoryModalOpen(true);
    };

    const handleCategoryModalClose = () => {
        setIsCategoryModalOpen(false);
    };

    useEffect(() => {
        getMaterials();
        getCategories();
    }, []);

    const handleChange = (value) => {
        setSearchInput(value);
        if (value.length > 0) {
            let results = materialData.filter((material) => {
                return matches(material, value);
            });
            setSearchData(results)
        } else {
            setSearchData(materialData)
        }
    };

    function matches(material, search) {
        const ts = search.trim().toLowerCase();
        const abbrev_de = material.abbreviation_de.trim().toLowerCase();
        const exp_de = material.explanation_de?.trim().toLowerCase() ?? "";
        const abbrev_en = material.abbreviation_en.trim().toLowerCase();
        const exp_en = material.explanation_en?.trim().toLowerCase() ?? "";
        return abbrev_de.includes(ts) || exp_de.includes(ts) || abbrev_en.includes(ts) || exp_en.includes(ts);
    }


    const getMaterials = async () => {
        setIsLoading(true);
        try {
            const { data } = await axios.get(
                MATERIALS_URL,
                {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
            const mappedData = data
                .sort((a, b) => (a.name > b.name) ? 1 : -1);

            setSearchData(mappedData);
            setMaterialData(mappedData);
        } catch (err) {
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getCategories = async () => {
        setIsLoading(true);
        try {
            const { data } = await axios.get(
                CATEGORIES_URL,
                {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
            const mappedData = data
                .sort((a, b) => (a.name > b.name) ? 1 : -1);
            setCategoryData(mappedData);
        } catch (err) {
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleMaterialChange = async (id, field, value) => {
        const updatedMaterials = [...materialData];
        const updatedSearchMaterials = [...searchData];
        updatedMaterials.map(material => {
            if (material.id === id) {
                material[field] = value.replace(",", ".");
            }
            return material;
        });
        updatedSearchMaterials.map(material => {
            if (material.id === id) {
                material[field] = value.replace(",", ".");
            }
            return material;
        });
        setSearchData(updatedSearchMaterials);
        setMaterialData(updatedMaterials);
    }

    const handleCategoryChange = async (id, field, value) => {
        const updatedCategories = [...categoryData];
        updatedCategories.map(category => {
            if (category.id === id) {
                category[field] = value;
            }
            return category;
        });
        setCategoryData(updatedCategories);
    }

    const uploadCsv = async (image) => {
        const URL = selectedTab === 0 ? `/admin/materials/update-with-csv` : `/admin/materials/recyclate/update-with-csv`;
        let data = new FormData();
        data.append('file', image, image.name);

        axios.post(URL, data, {
            headers: {
                'accept': 'application/json',
                'Accept-Language': 'en-US,en;q=0.8',
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                'Authorization': `Bearer ${auth.accessToken}`,
            }
        })
            .then((response) => {
                setErr(null);
                getMaterials();
            }).catch((error) => {
                setErr("Error uploading csv: " + error.response?.data);
            });
    }

    const downloadCsv = async () => {
        setDownloadLoading(true);
        const URL = selectedTab === 0 ? `/admin/materials/download-template-csv` : `/admin/materials/recyclate/download-template-csv`;
        try {
            const response = await axios.get(URL, {
                headers: {
                    'Authorization': `Bearer ${auth.accessToken}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                responseType: 'blob',  // Specify the response type as blob
            });
            if (response.status !== 200) {
                setErr('Failed to fetch csv data');
            }
            const blob = new Blob([response.data], { type: 'text/csv' });
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = 'data.csv';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } catch (error) {
            console.error('Error downloading CSV:', error);
        } finally {
            setDownloadLoading(false);
        }
    }

    const updateMaterials = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const materials = materialData.map((material, i) => {
            Object.keys(material).slice(5).map((propName, n) => {
                if (propName !== "created_at") {
                    const parsedFloat = parseFloat(material[propName]);
                    material[propName] = isNaN(parsedFloat) ? 0.00 : parsedFloat;
                }
            })
            return material;
        })
        const UPDATE_MATERIAL_URL = `/admin/materials/update`
        try {
            const { data } = await axios.post(
                UPDATE_MATERIAL_URL,
                materials,
                {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
        } catch (err) {
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    }

    const removeMaterials = async () => {
        setIsLoading(true);
        try {
            await axios.post(
                REMOVE_MATERIALS_URL,
                selectedMaterials,
                {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
        } catch (err) {
            setErr(err.message);
        } finally {
            setSelectedMaterials([]);
            getMaterials();
        }
    }

    const removeCategories = async () => {
        setIsLoading(true);
        try {
            await axios.post(
                REMOVE_CATEGORIES_URL,
                selectedCategories,
                {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
        } catch (err) {
            setErr(err.message);
        } finally {
            setSelectedCategories([]);
            getCategories();
        }
    }

    const selectMaterial = (id, checked) => {
        const materialIsSelected = selectedMaterials.find(u => u === id);
        if (checked && !materialIsSelected) {
            setSelectedMaterials(selectedMaterials => [...selectedMaterials, id]);
        } else if (!checked && materialIsSelected) {
            setSelectedMaterials(selectedMaterials.filter(u => u !== id));
        }
    }

    const selectCategory = (id, checked) => {
        const categoryIsSelected = selectedCategories.find(u => u === id);
        if (checked && !categoryIsSelected) {
            setSelectedCategories(selectedCategories => [...selectedCategories, id]);
        } else if (!checked && categoryIsSelected) {
            setSelectedCategories(selectedCategories.filter(u => u !== id));
        }
    }

    const selectAllCategories = (checked) => {
        setSelectedAllCategories(checked);
        if (checked) {
            setSelectedCategories(materialData.map(m => m.id));
        } else {
            setSelectedCategories([]);
        }
    }

    const selectAll = (checked) => {
        setSelectAllMaterials(checked);
        if (checked) {
            setSelectedMaterials(materialData.map(m => m.id));
        } else {
            setSelectedMaterials([]);
        }
    }

    const addMaterialToCategory = () => {

    }


    const Selection = () => {
        return (
            <div className="d-flex align-items-center">
                <span className="fs-5 me-3">
                    <span id="datatableCounter">{selectedMaterials.length + " "}</span>
                    selected
                </span>
                <a className="btn btn-outline-danger btn-sm" href="javascript:;" onClick={() => removeMaterials()}>
                    <i className="bi-trash" /> Delete
                </a>
                <button type="button" onClick={() => addMaterialToCategory()} className="btn btn-outline-primary ms-2">
                    <i className="bi bi-clipboard-plus"></i> Add to category
                </button>
            </div>
        );
    }

    const CategorySelection = () => {
        return (
            <div className="d-flex align-items-center">
                <span className="fs-5 me-3">
                    <span id="datatableCounter">{selectedCategories.length + " "}</span>
                    selected
                </span>
                <a className="btn btn-outline-danger btn-sm" href="javascript:;" onClick={() => removeCategories()}>
                    <i className="bi-trash" /> Delete
                </a>
            </div>
        );
    }


    return (
        <div style={{ width: "100%" }}>
            {isMaterialModalOpen && <NewMaterialModal heading={"Add new material"} paragraph={"Create a material with the following name"} handleClose={handleMaterialModalClose} url={NEW_MATERIAL_URL} reload={getMaterials} />}
            {isCategoryModalOpen && <NewMaterialModal heading={"Add new material category"} paragraph={"Create a material category with the following name"} handleClose={handleCategoryModalClose} url={NEW_CATEGORY_URL} reload={getCategories} />}
            <h1>Materialen</h1>
            {isLoading ? (
                <Loading />
            ) : (
                err ? (
                    <div className={err ? "alert alert-soft-danger" : "offscreen"} role="alert">
                        {err}
                    </div>
                ) : (
                    <div>
                        <div className="text-center" style={{ width: "100%", marginTop: "10px" }}>
                            <ul className="nav nav-segment nav-pills mb-7" role="tablist">
                                {tabs.map((tab) => {
                                    return (
                                        <li className="nav-item">
                                            <a
                                                onClick={function (e) { setSelectedTab(tab.index); }}
                                                className={"nav-link" + (tab.index === selectedTab ? " active" : "")}
                                                id="nav-three-eg1-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#nav-three-eg1"
                                                role="tab" aria-controls="nav-three-eg1"
                                                aria-selected={tab.index === selectedTab ? "true" : "false"}
                                                style={{ cursor: "pointer" }}
                                            >
                                                {tab.name}
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div>
                            <div className="mb-4 row justify-content-between align-items-center">
                                <div className="col-auto py-1">
                                    <div className="mb-4 row justify-content-between align-items-center">
                                        <div className="col-auto py-1">
                                            <div className="input-group input-group-merge">
                                                <form>
                                                    <input
                                                        type="text"
                                                        className="js-form-search form-control"
                                                        placeholder="Search..."
                                                        onChange={(e) => handleChange(e.target.value)}
                                                        value={searchInput}
                                                        data-hs-form-search-options='{
                            "clearIcon": "#clearIcon2",
                            "defaultIcon": "#defaultClearIconToggleEg"
                            }'
                                                    />
                                                </form>
                                                <button type="button" className="input-group-append input-group-text">
                                                    <i id="clearIcon2" className="bi-x-lg" style={{ display: "none" }} />
                                                    <i
                                                        id="defaultClearIconToggleEg"
                                                        className="bi-search"
                                                        style={{ display: "false" }}
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-auto py-1">
                                            <button type="button" onClick={() => openNewMaterialModal()} className="btn btn-primary">
                                                <i className="bi bi-clipboard-plus"></i> Material hinzufügen
                                            </button>
                                        </div>
                                        <div className="col-auto py-1">
                                            <button type="button" className="btn btn-ghost-primary" onClick={() => downloadCsv()}>{downloadLoading ? "Downloading..." : "Download Csv"}</button>
                                        </div>
                                        <div className="col-auto py-1">
                                            <label for="file" style={{ color: "#089c44", cursor: "pointer", padding: "" }}>Upload Csv
                                                <input
                                                    id="file"
                                                    type="file"
                                                    style={{ visibility: "hidden", height: "0px", width: "0px" }}
                                                    name="myImage"
                                                    onChange={(event) => {
                                                        uploadCsv(event.target.files[0]);
                                                    }}
                                                /></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto py-1">
                                    {selectedMaterials.length > 0 && <Selection />}
                                </div>
                            </div>
                            <table className="table table-text-center">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">
                                            <input type="checkbox" className="form-check-input" checked={selectAllMaterials}
                                                onChange={event => selectAll(event.target.checked)}
                                            />
                                        </th>
                                        <th scope="col">#</th>
                                        <th scope="col">Abkürung (en)</th>
                                        <th scope="col">Name (en)</th>
                                        <th scope="col">Abkürung (de)</th>
                                        <th scope="col">Name (de)</th>
                                        {selectedTab === 0 && (<>
                                            <th scope="col">acidification</th>
                                            <th scope="col">climate change</th>
                                            <th scope="col">ecotoxicity freshwater</th>
                                            <th scope="col">energy resources non-renewable</th>
                                            <th scope="col">eutrophication freshwater</th>
                                            <th scope="col">eutrophication marine</th>
                                            <th scope="col">eutrophication terrestrial</th>
                                            <th scope="col">human toxicity carcinogenic</th>
                                            <th scope="col">human toxicity non-carcinogenic</th>
                                            <th scope="col">ionising radiation human health</th>
                                            <th scope="col">land use</th>
                                            <th scope="col">material resources</th>
                                            <th scope="col">ozone depletion</th>
                                            <th scope="col">particulate matter formation</th>
                                            <th scope="col">photochemical ozone formation</th>
                                            <th scope="col">water use</th>
                                            <th scope="col">recyclate value</th></>)}
                                             {selectedTab  === 1 && (<>
                                                <th scope="col">acidification</th>
                                                <th scope="col">climate change</th>
                                                <th scope="col">ecotoxicity freshwater</th>
                                                <th scope="col">energy resources non-renewable</th>
                                                <th scope="col">eutrophication freshwater</th>
                                                <th scope="col">eutrophication marine</th>
                                                <th scope="col">eutrophication terrestrial</th>
                                                <th scope="col">human toxicity carcinogenic</th>
                                                <th scope="col">human toxicity non-carcinogenic</th>
                                                <th scope="col">ionising radiation human health</th>
                                                <th scope="col">land use</th>
                                                <th scope="col">material resources</th>
                                                <th scope="col">ozone depletion</th>
                                                <th scope="col">particulate matter formation</th>
                                                <th scope="col">photochemical ozone formation</th>
                                                <th scope="col">water use</th>
                                                <th scope="col">rate</th>
                                                <th scope="col">heating value</th>
                                                <th scope="col">littering impact</th>
                                            </>)}
                                            {selectedTab  === 2 && (<th scope="col">Multi-use factor</th>)}

                                        <th scope="col">Creation date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {searchData.map((material, n) => {
                                        return (<tr key={material.id} style={{ backgroundColor: selectedMaterials.includes(material.id) ? "rgb(184, 245, 184)" : "" }}>
                                            <td>
                                                <input type="checkbox" className="form-check-input" checked={selectedMaterials.includes(material.id)}
                                                    onChange={event => selectMaterial(material.id, event.target.checked)}
                                                />
                                            </td>
                                            <td scope="row">{n + 1}</td>
                                            {Object.keys(material).slice(1).map((propName, n) => {
                                                if (selectedTab === 0 && ((n < 40 && n > 20) || n === 40)) {
                                                    return null;
                                                } else if (selectedTab === 1 && ((n < 21 && n > 3) || n === 40)) {
                                                    return null;
                                                } else if (selectedTab === 2 && ((n < 40 && n > 20) || (n < 21 && n > 3))) {
                                                    return null;
                                                }
                                                if (propName === "created_by") {
                                                    return null;
                                                }
                                                if (propName === "created_at") {
                                                    return <td>{moment(material[propName]).format('D.M.YYYY')}</td>;
                                                }
                                                console.log("showing: " + propName + " " + n);
                                                return (<td key={material.id + '1337' + propName}>
                                                    <div className="input-group input-group-borderless input-group-flush">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={material[propName]}
                                                            onChange={(e) => handleMaterialChange(material.id, propName, e.target.value)}
                                                            style={{
                                                                width: `${(String(material[propName])?.length ?? 1) + 4}ch`,
                                                                textAlign: "center",
                                                                padding: '5px',
                                                            }}
                                                        />
                                                    </div>
                                                </td>)
                                            })}

                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                            <center>
                                <button type="submit" className="btn btn-primary" onClick={(e) => updateMaterials(e)}>Speichern</button>
                            </center>
                        </div>
                    </div>
                )
            )}
        </div>
    );
}

export default Materials;