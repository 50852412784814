import { useParams } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import axios from "../../../../api/axios";
import useAuth from "../../../../hooks/useAuth";
import LabForm from "./LabForm";
import Loading from "../../../general/status/Loading";
import Error from "../../../general/status/Error";
import AssignedLabTable from "./AssignedLabTable";

const SET_LAB_URL = "/lab/set";
const DELETE_LAB_URL = "/lab/delete";
const LAB_URL = "/lab";

const Lab = ({ algoVersion }) => {
  const { productId } = useParams();
  const { auth } = useAuth();
  const [err, setErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [labs, setLabs] = useState(null);

  useEffect(() => {
    getLabInfo();
  }, []);

  const getLabInfo = async () => {
    setIsLoading(true);
    const URL = `/products/${productId}/labs`;
    try {
      const { data } = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setLabs(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteLab = async () => {
    setIsLoading(true);
    const URL = `/products/${productId}/lab/delete`;
    try {
      const { data } = await axios.post(
        URL,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
      );
      getLabInfo();
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const assignLab = async (labId) => {
    setIsLoading(true);
    const URL = `/products/${productId}/lab/set`;
    try {
      const { data } = await axios.post(
        URL,
        {
          labId: labId,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
      );
      getLabInfo();
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const LabAssignment = () => {
    return (
      <div className="card card-body mb-3 mb-lg-5">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <div className="col-auto py-1">
                <h6 className="card-subtitle">Current Lab:</h6>
                <h5>Name of selected Lab</h5>
              </div>
              <div className="col-auto py-1">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => deleteLab()}
                ></button>
              </div>
            </div>
            <button type="button" className="btn btn-ghost-primary">
              Assign Lab
            </button>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <AssignedLabTable />
      <LabForm algoVersion={algoVersion} />,
    </>
  );
};

export default Lab;
