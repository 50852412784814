import { useContext, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import Loading from "../general/status/Loading";
import Error from "../general/status/Error";

const NewMaterialModal = ({ heading, paragraph, handleClose, url, reload }) => {
  const { auth } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState('');
  const [abbreviation, setAbbreviation] = useState("");

  const createMaterial = async () => {
    setIsLoading(true);
    try {
        const { data } = await axios.post(
            url,
            {
              abbreviation: abbreviation
            },
            {
                headers: {
                    'Authorization': `Bearer ${auth.accessToken}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            },
        );
        reload();
        handleClose();
    } catch (err) {
        setErr(err.message);
    } finally {
        setIsLoading(false);
    }
};

  return (
    <div id="exampleModalCenter" className="modal fade show" tabindex="-1" aria-labelledby="exampleModalCenterTitle" style={{display: "block", paddingLeft: "0px", backgroundColor: "rgba(0,0,0,0.4)"}} aria-modal="true" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="display-5" id="exampleModalCenterTitle">
              {heading}
            </h5>
            <button
              onClick={handleClose}
              style={{marginTop: "-30px"}}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
          {isLoading ? <Loading /> : (err ? <Error err={err} /> : (<></>))}
            <form>
              <div>
                <label className="form-label" htmlFor="signinSrEmail">
                    {paragraph}
                  </label>
                  <input
                    type="text"
                    key="lab-name"
                    className="form-control form-control-lg"
                    name="username"
                    id="signinSrEmail"
                    onChange={(e) => setAbbreviation(e.target.value)}
                    tabIndex={1}
                    value={abbreviation}
                    placeholder=""
                    aria-label="00000"
                    required=""
                  />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" onClick={handleClose} className="btn btn-white" data-bs-dismiss="modal">
              Close
            </button>
            <button type="button" onClick={() => createMaterial()} className="btn btn-primary">
            {isLoading ? <div className="lds-ripple"><div></div><div></div></div> : heading}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewMaterialModal;