import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import LoadingAndErrIndicator from "../../general/status/LoadingAndErrorIndicator";
import React from "react";

const QuickCheck = () => {
  const { productId } = useParams();
  const { auth } = useAuth();
  const [err, setErr] = useState("");
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getResult();
  }, [productId]);

  const getResult = async () => {
    setIsLoading(true);
    const url = `/products/${productId}/check`;
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setResults(data);
      setErr("");
    } catch (err) {
      if (err?.response?.status === 418) {
        setErr(
          `⚠️ Missing ${err.response.data.name} in the ${err.response.data.location}, which is required for the result`,
        );
      } else {
        setErr(err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div
      className="card mb-3 mb-lg-5"
      style={{ maxWidth: "1500px", margin: "auto" }}
    >
      <div className="card-header">
        <div className="row justify-content-between align-items-center flex-grow-1">
          <div className="col-md">
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="card-header-title">SUSYinventory</h3>
            </div>
          </div>
        </div>
      </div>
      {err || isLoading ? (
        <div className="card-body">
          <LoadingAndErrIndicator
            err={err}
            isLoading={isLoading}
            loadingMessage={"Fetching QUICKcheck..."}
          />{" "}
        </div>
      ) : (
        <table className="table table-text-center">
          <thead className="thead-light">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Field</th>
              <th scope="col">Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(results).map(([key, value], index) => {
              return (
                <tr key={index}>
                  <td scope="row">{index + 1}</td>
                  <td scope="row">{key}</td>
                  <td
                    style={{
                      color:
                        Math.abs(value.indicator) > 0.05
                          ? `rgb(8, ${Math.min(156, 68 + value.indicator * (156 - 68))}, 68, 200)` // Green tint closer to rgb(8, 156, 68)
                          : Math.abs(value.indicator) > 0.05
                            ? `rgba(${Math.min(200, 150 + Math.abs(value.indicator) * 50)}, 0, 0, 200)` // Subtle red tint
                            : "#677788", // Default color for indicators near 0
                    }}
                    scope="row"
                  >
                    {value.value.split("\n").map((line, index) => (
                      <React.Fragment key={index + 123456}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default QuickCheck;
