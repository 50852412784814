import { useContext, useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import moment from "moment";
import SearchBar from "../../general/SearchBar";
import Loading from "../../general/status/Loading";
import GhostError from "../../general/status/GhostError";
import InviteUserModal from "./InviteUserModal";
import LoadingAndErrIndicator from "../../general/status/LoadingAndErrorIndicator";

const USERS_URL = '/admin/users';
const DELETE_USERS_URL = '/admin/users/delete'; 

const UserTable = () => {
    const { auth } = useAuth();
    const [userData, setUserData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchInput, setSearchInput] = useState("");
    const [err, setErr] = useState('');
    const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectAllUsers, setSelectAllUsers] = useState(false);

    useEffect(() => {
        getUsers();
    }, []);

    function matches(user, search) {
        console.log()
        const email = user.email.trim();
        return email.includes(search.trim().toLowerCase());
    }

    const getUsers = async () => {
        setIsLoading(true);
        try {
            const { data } = await axios.get(
                USERS_URL,
                {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
            let n = 0;
            const mappedData = data
                .sort((a, b) => (a.email > b.email) ? 1 : -1)
                .map((user) => {
                    n += 1;
                    return {
                        number: n,
                        id: user.id,
                        email: user.email,
                        groupName: user.groupName,
                    }
                })

            setSearchData(mappedData);
            setUserData(mappedData);
        } catch (err) {
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteUsers = async () => {
        setIsLoading(true);
        try {
            const { data } = await axios.post(
                DELETE_USERS_URL,
                selectedUsers,
                {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
            setSelectedUsers([])
            setErr(null)
        } catch (err) {
            setErr(err.message);
        } finally {
            getUsers();
        }
    }

    const handleChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        if (e.target.value.length > 0) {
            let results = userData.filter((user) => {
                return matches(user, e.target.value);
            });
            setSearchData(results)
        } else {
            setSearchData(userData)
        }
    };

    const selectUser = (id, checked) => {
        const userIsSelected = selectedUsers.find(u => u === id);
        if (checked && !userIsSelected) {
            setSelectedUsers(selectedUsers => [...selectedUsers, id]);
        } else if (!checked && userIsSelected) {
            setSelectedUsers(selectedUsers => selectedUsers.filter(u => u !== id));
        }
    }

    const selectAll = (checked) => {
        setSelectAllUsers(checked);
        if (checked) {
            setSelectedUsers(userData.map(u => u.id));
        } else {
            setSelectedUsers([]);
        }
    }

    const Selection = () => {
        return (
            <div className="d-flex align-items-center">
                <span className="fs-5 me-3">
                    <span id="datatableCounter">{selectedUsers.length + " "}</span>
                    selected
                </span>
                <a className="btn btn-outline-danger btn-sm" href="javascript:;" onClick={() => deleteUsers()}>
                    <i className="bi-trash" /> Delete
                </a>
            </div>
        );
    }

    return (<>
        {isInviteUserModalOpen && <InviteUserModal handleClose={() => setIsInviteUserModalOpen(false)} />}
        <div className="card mb-3 mb-lg-5">
            <div className="card-header">
                <div className="row justify-content-between align-items-center flex-grow-1">
                    <div className="col-md">
                        <div className="d-flex justify-content-between align-items-center">
                            <h3 className="card-header-title">Users</h3>
                        </div>
                    </div>
                    <div className="col-auto py-1">
                        {selectedUsers.length > 0 && <Selection />}
                    </div>
                    <div className="col-auto">
                        <div className="row align-items-sm-center">
                            <div className="col-sm-auto">
                                <button type="button" onClick={() => setIsInviteUserModalOpen(true)} className="btn btn-ghost-primary">
                                    <i className="bi bi-clipboard-plus"></i> Create Invite
                                </button>
                            </div>
                            <div className="col-md">
                                <SearchBar handleChange={handleChange} searchInput={searchInput} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LoadingAndErrIndicator err={err} isLoading={isLoading} loadingMessage={"Fetching users..."}/>
            {!isLoading && !err && (<table className="table table-text-center">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col"><input type="checkbox" className="form-check-input" checked={selectAllUsers}
                                        onChange={event => selectAll(event.target.checked)}
                                        /></th>
                                    <th scope="col">#</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Role</th>
                                </tr>
                            </thead>
                            <tbody>
                                {searchData.map((user) => {
                                    return (<tr>
                                        <td>
                                        <input type="checkbox" className="form-check-input" checked={selectedUsers.includes(user.id)}
                                        onChange={event => selectUser(user.id, event.target.checked)}
                                        />
                                        </td>
                                        <td scope="row">{user.number}</td>
                                        <td> 
                                            <Link to={"./" + user.id}>
                                                <a className="nav-link" href="#">
                                                    {user.email} 
                                                </a>
                                            </Link>
                                        </td>
                                        <td>{user.groupName}</td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>)}
        </div>
    </>)
}

export default UserTable