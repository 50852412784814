import { useContext, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import moment from "moment";
import SearchBar from "../general/SearchBar";
import Loading from "../general/status/Loading";
import GhostError from "../general/status/GhostError";
import AddJobModal from "./AddJobModal";

const JOBS_URL = "/jobs";
const REMOVE_JOBS_URL = "/jobs/delete";

const JobTable = () => {
  const { auth } = useAuth();
  const [jobData, setJobData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [err, setErr] = useState("");
  const [isNewJobModalOpen, setIsNewJobModalOpen] = useState(false);
  const [selectAllJobs, setSelectAllJobs] = useState(false);
  const [selectedJobs, setSelectedJobs] = useState([]);

  useEffect(() => {
    getJobs();
  }, []);

  function matches(job, search) {
    const s = search.trim().toLowerCase();
    const name = job.name.trim().toLowerCase();
    const creator = job.createdBy.trim().toLowerCase();
    return name.includes(s) || creator.includes(s);
  }

  const removeJobs = async (jobId) => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(REMOVE_JOBS_URL, selectedJobs, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setSelectedJobs([]);
      getJobs();
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getJobs = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(JOBS_URL, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const mappedData = data
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map(({ job, creator }, n) => {
          return {
            number: n + 1,
            id: job.id,
            name: job.name,
            createdAt: job.createdAt,
            createdBy: creator.email,
            userId: creator.id,
          };
        });

      setSearchData(mappedData);
      setJobData(mappedData);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const selectJob = (id, checked) => {
    const jobIsSelected = selectedJobs.find((j) => j === id);
    if (checked && !jobIsSelected) {
      setSelectedJobs((selectedJobs) => [...selectedJobs, id]);
    } else if (!checked && jobIsSelected) {
      setSelectedJobs(selectedJobs.filter((j) => j !== id));
    }
  };

  const selectAll = (checked) => {
    setSelectAllJobs(checked);
    if (checked) {
      setSelectedJobs(jobData.map((u) => u.id));
    } else {
      setSelectedJobs([]);
    }
  };

  const Selection = () => {
    return (
      <div className="d-flex align-items-center">
        <span className="fs-5 me-3">
          <span id="datatableCounter">{selectedJobs.length + " "}</span>
          selected
        </span>
        <div style={{ width: "20px" }}></div>
        <a
          className="btn btn-outline-danger btn-sm"
          href="javascript:;"
          onClick={() => removeJobs()}
        >
          <i className="bi-trash" /> Remove
        </a>
      </div>
    );
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
    if (e.target.value.length > 0) {
      let results = jobData.filter((job) => {
        return matches(job, e.target.value);
      });
      setSearchData(results);
    } else {
      setSearchData(jobData);
    }
  };

  function matches(product, search) {
    const name = product?.name?.trim().toLowerCase() ?? "";
    const gtin = product?.gtin?.trim().toLowerCase() ?? "";
    const companyName = product?.company_name?.trim().toLowerCase() ?? "";
    const s = search.trim().toLowerCase();
    return name.includes(s) || gtin.includes(s) || companyName.includes(s);
  }

  const LoadingAndErrIndicator = () => {
    return err || isLoading ? (
      <div className="my-4">
        {isLoading && <Loading desc={"Fetching jobs..."} />}
        {err && <GhostError err={err} />}
      </div>
    ) : (
      <></>
    );
  };

  return (
    <>
      {isNewJobModalOpen && (
        <AddJobModal handleClose={() => setIsNewJobModalOpen(false)} />
      )}
      <div className="card mb-3 mb-lg-5">
        <div className="card-header">
          <div className="row justify-content-between align-items-center flex-grow-1">
            <div className="col-md">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="card-header-title">Jobs</h3>
              </div>
            </div>
            <div className="col-auto">
              <div className="row align-items-sm-center">
                <div className="col-sm-auto">
                  {selectedJobs.length > 0 && <Selection />}
                </div>
                <div className="col-sm-auto">
                  <button
                    type="button"
                    onClick={() => setIsNewJobModalOpen(true)}
                    className="btn btn-ghost-primary"
                  >
                    <i className="bi bi-clipboard-plus"></i> Create Job
                  </button>
                </div>
                <div className="col-md">
                  <SearchBar
                    handleChange={handleChange}
                    searchInput={searchInput}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoadingAndErrIndicator />
        {!isLoading && !err && (
          <table className="table table-text-center">
            <thead className="thead-light">
              <tr>
                <th>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={selectAllJobs}
                    onChange={(event) => selectAll(event.target.checked)}
                  />
                </th>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Creator</th>
                <th scope="col">Creation date</th>
              </tr>
            </thead>
            <tbody>
              {searchData.map((job) => {
                return (
                  <tr key={job.id}>
                    <td>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={selectedJobs.includes(job.id)}
                        onChange={(event) =>
                          selectJob(job.id, event.target.checked)
                        }
                      />
                    </td>
                    <td scope="row">{job.number}</td>
                    <td>
                      <Link to={"./jobs/" + job.id}>
                        <a className="nav-link" href="#">
                          {job.name}
                        </a>
                      </Link>
                    </td>
                    <td>
                      <Link to={"../users/" + job.userId}>
                        <a className="nav-link" href="#">
                          {job.createdBy ?? "-"}
                        </a>
                      </Link>
                    </td>
                    <td>
                      {job.createdAt
                        ? moment(job.createdAt).format("D.M.YYYY")
                        : "-"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default JobTable;
