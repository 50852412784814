
const Modal = ({ children, title, handleClose }) => {

    return (<div id="exampleModalCenter" className="modal fade show" tabindex="-1" aria-labelledby="exampleModalCenterTitle" style={{ display: "block", paddingLeft: "0px", backgroundColor: "rgba(0,0,0,0.4)" }} aria-modal="true" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="display-5" id="exampleModalCenterTitle">
                        {title}
                    </h5>
                    <button
                        onClick={handleClose}
                        style={{ marginTop: "-30px" }}
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    />
                </div>
                {children}
            </div></div></div>)
}

export default Modal