import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import Loading from "../general/status/Loading";
import ProductStars from "../admin/ProductStars";
import placeholderImage from "../../assets/img/placeholder.jpg";

const toMedal = (place) => {
  switch (place) {
    case 0:
      return <h1 style={{ fontSize: "32px" }}>🥇</h1>;
    case 1:
      return <h1 style={{ fontSize: "32px" }}>🥈</h1>;
    case 2:
      return <h1 style={{ fontSize: "32px" }}>🥉</h1>;
    default:
      return `#${place + 1}`;
  }
};

const ComparisonTable = ({
  canSelect = false,
  results,
  productImages,
  loadingProductImages,
  selectedResults,
  setSelectedResults,
}) => {
  const [folded, setFolded] = useState([true, true, true, true]);

  const selectResult = (id, checked) => {
    const resultIsSelected = selectedResults.find((u) => u === id);
    if (checked && !resultIsSelected) {
      setSelectedResults((selectedResults) => [...selectedResults, id]);
    } else if (!checked && resultIsSelected) {
      setSelectedResults(selectedResults.filter((u) => u !== id));
    }
  };
  /*
  const TableColumn = ({ fields, results, productIds }) => {
    return Object.keys(flattenObject(results[0])).map((propName, i) => {
      return (
        <>
          <tr>
            {results.map((r, f) => {
              var isBenchmark = productIds[f] === benchmark;
              return (
                <td
                  scope="row"
                  style={{
                    borderTop: isBenchmark
                      ? "none"
                      : "rgba(231, 234, 243, 0.7) solid 1px",
                    borderBottom: "none",
                    backgroundColor: isBenchmark ? "#b8f5b8" : "white",
                  }}
                >
                  {f === 0 && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h5 style={{ marginTop: "10px", marginBottom: "5px" }}>
                        {fields[i].name}
                      </h5>
                      <a
                        style={{
                          marginLeft: "10px",
                          marginTop: "8px",
                          fontSize: "0.875rem",
                        }}
                      >
                        {fields[i].index}
                      </a>
                    </div>
                  )}
                </td>
              );
            })}
          </tr>
          <tr>
            {results.map((r, f) => {
              var isBenchmark = productIds[f] === benchmark;
              var flat = flattenObject(r);
              return (
                <td
                  scope="row"
                  style={{
                    borderTop: isBenchmark
                      ? "none"
                      : "rgba(231, 234, 243, 0.7) solid 1px",
                    borderBottom: "none",
                    backgroundColor: isBenchmark ? "#b8f5b8" : "white",
                  }}
                >
                  {(flat[propName] ?? 0).toFixed(3)}
                </td>
              );
            })}
          </tr>
        </>
      );
    });
  };*/

  return (
    <table className="table table-text-center">
      <tbody>
        <tr>
          {results.product_results.map((p) => {
            return (
              <>
                <td
                  scope="row"
                  style={{
                    border: "none",
                    backgroundColor:
                      results.benchmark_id === p.info.id ? "#b8f5b8" : "white",
                    borderTopLeftRadius: "0.75rem",
                    borderTopRightRadius: "0.75rem",
                  }}
                >
                  <h5>{results.benchmark_id === p.info.id && "Benchmark"}</h5>
                </td>
              </>
            );
          })}
        </tr>
        {canSelect && (
          <tr>
            {results.product_results.map((p) => {
              return (
                <>
                  <td
                    style={{
                      border: "none",
                      backgroundColor:
                        results.benchmark_id === p.info.id
                          ? "#b8f5b8"
                          : "white",
                      minWidth: "200px",
                    }}
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectedResults.includes(p.info.id)}
                      onChange={(event) =>
                        selectResult(p.info.id, event.target.checked)
                      }
                    />
                  </td>
                </>
              );
            })}
          </tr>
        )}
        <tr>
          {results.product_results.map((p, i) => {
            return (
              <>
                <td
                  scope="row"
                  style={{
                    border: "none",
                    backgroundColor:
                      results.benchmark_id === p.info.id ? "#b8f5b8" : "white",
                  }}
                >
                  {/*<h5>Place:</h5>*/}
                  {toMedal(i)}
                </td>
              </>
            );
          })}
        </tr>
        <tr>
          {results.product_results.map((p) => {
            return (
              <>
                <td
                  scope="row"
                  style={{
                    border: "none",
                    backgroundColor:
                      results.benchmark_id === p.info.id ? "#b8f5b8" : "white",
                  }}
                >
                  {loadingProductImages.get(p.info.id) ? (
                    <Loading />
                  ) : (
                    <img
                      className="img-fluid"
                      width={"200px"}
                      src={productImages.get(p.info.id) ?? placeholderImage}
                      alt="Produktbild"
                    />
                  )}
                </td>
              </>
            );
          })}
        </tr>
        <tr>
          {results.product_results.map((p) => {
            return (
              <>
                <td
                  scope="row"
                  style={{
                    border: "none",
                    backgroundColor:
                      results.benchmark_id === p.info.id ? "#b8f5b8" : "white",
                    color: "#089c44",
                  }}
                >
                  Algo {p.info.algorithm_version}
                </td>
              </>
            );
          })}
        </tr>

        <tr>
          {results.product_results.map((p) => {
            return (
              <>
                <td
                  scope="row"
                  style={{
                    border: "none",
                    backgroundColor:
                      results.benchmark_id === p.info.id ? "#b8f5b8" : "white",
                  }}
                >
                  <Link to={"../product/" + p.info.id}>
                    <h5>{p.info.name}</h5>
                  </Link>
                </td>
              </>
            );
          })}
        </tr>

        <tr>
          {results.product_results.map((p) => {
            return (
              <>
                <td
                  scope="row"
                  style={{
                    border: "none",
                    backgroundColor:
                      results.benchmark_id === p.info.id ? "#b8f5b8" : "white",
                  }}
                >
                  {p.info.is_optimized ? (
                    <h5 style={{ color: "#089c44" }}>
                      <i className="bi bi-tools" /> DIGITAL TWIN
                    </h5>
                  ) : (
                    <h5 className="card-subtitle mb-2">PRODUCT</h5>
                  )}
                </td>
              </>
            );
          })}
        </tr>

        <tr>
          {results.product_results.map((p) => {
            return (
              <>
                <td
                  scope="row"
                  style={{
                    border: "none",
                    backgroundColor:
                      results.benchmark_id === p.info.id ? "#b8f5b8" : "white",
                  }}
                >
                  {p.info.company_name ?? "-"}
                </td>
              </>
            );
          })}
        </tr>

        <tr>
          {results.product_results.map((p) => {
            return (
              <>
                <td
                  scope="row"
                  style={{
                    border: "none",
                    backgroundColor:
                      results.benchmark_id === p.info.id ? "#b8f5b8" : "white",
                  }}
                >
                  {p.info.gtin ?? "-"}
                </td>
              </>
            );
          })}
        </tr>
        <tr>
          {results.product_results.map((p) => {
            return (
              <td
                scope="row"
                style={{
                  border: "none",
                  backgroundColor:
                    results.benchmark_id === p.info.id ? "#b8f5b8" : "white",
                }}
              >
                <ProductStars
                  score={p.check_score}
                  jump={results.max_score / 10.0}
                />
              </td>
            );
          })}
        </tr>
        {results.criteria.map((c) => {
          return (
            <>
              <tr style={{ borderBottom: "none" }}>
                <td
                  scope="row"
                  style={{
                    border: "none",
                    backgroundColor:
                      results.benchmark_id ===
                      results.product_results[0].info.id
                        ? "#b8f5b8"
                        : "white",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <h5 style={{ marginTop: "10px", marginBottom: "5px" }}>
                      {c.criteria_english}
                    </h5>
                    <a
                      style={{
                        marginLeft: "10px",
                        marginTop: "8px",
                        fontSize: "0.875rem",
                      }}
                    >
                      {c.abbrev}
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                {results.product_results.map((p) => {
                  return (
                    <td
                      scope="row"
                      style={{
                        border: "none",
                        backgroundColor:
                          results.benchmark_id === p.info.id
                            ? "#b8f5b8"
                            : "white",
                      }}
                    >
                      {p.result[c.abbrev]?.toFixed(3) ?? "-"}
                    </td>
                  );
                })}
              </tr>
            </>
          );
        })}
        <tr>
          {results.product_results.map((p) => {
            return (
              <td
                scope="row"
                style={{
                  borderTop: "solid 2px",
                  borderBottom: "none",
                  backgroundColor:
                    results.benchmark_id === p.info.id ? "#b8f5b8" : "white",
                  borderBottomLeftRadius: "0.75rem",
                  borderBottomRightRadius: "0.75rem",
                }}
              >
                <h5>{p.check_score.toFixed(3)}</h5>
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
};

export default ComparisonTable;
