
const Warning = ({ warning }) => {
    return (<div className="mx-auto text-center">
        <span className="icon icon-soft-danger mb-2">
            <i className="bi bi-cone-striped"></i>
        </span>
        <h6 style={{ color: "#ed4c78" }}>{warning ?? "Warning!"}</h6>
    </div>)
}

export default Warning;