import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import Loading from "../general/status/Loading";
import ProductStars from "../admin/ProductStars";
import placeholderImage from "../../assets/img/placeholder.jpg";
import ProductResultTable from "./ProductResultTable";
import LoadingAndErrIndicator from "../general/status/LoadingAndErrorIndicator";
import SelectedResultsModal from "./SelectedResultsModal";
import Warning from "../general/status/Warning";
const SUR_CHARGE = 1.1;

function flattenObject(ob) {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] == "object" && ob[i] !== null) {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;
        if (x === "score") {
          continue;
        }
        toReturn[i + "." + x] = flatObject[x];
      }
    } else {
      if (i === "score") {
        continue;
      }
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}

const toMedal = (place) => {
  switch (place) {
    case 0:
      return <h1 style={{ fontSize: "32px" }}>🥇</h1>;
    case 1:
      return <h1 style={{ fontSize: "32px" }}>🥈</h1>;
    case 2:
      return <h1 style={{ fontSize: "32px" }}>🥉</h1>;
    default:
      return `#${place + 1}`;
  }
};

const JOB_URL = "/jobs";
const Results = () => {
  const { jobId } = useParams();
  const { auth } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [err, setErr] = useState("");
  const [results, setResults] = useState([]);
  const [folded, setFolded] = useState([true, true, true, true]);
  const [maxScore, setMaxScore] = useState(null);
  const [productImages, setProductImages] = useState(new Map());
  const [loadingProductImages, setLoadingProductImages] = useState(new Map());
  const [scoreIds, setScoreIds] = useState([]);
  const [benchmark, setBenchmark] = useState(0);
  const [selectedResults, setSelectedResults] = useState([]);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [selectionModalOpen, setSelectionModalOpen] = useState(false);

  const selectResult = (id, checked) => {
    const resultIsSelected = selectedResults.find((u) => u === id);
    if (checked && !resultIsSelected) {
      setSelectedResults((selectedResults) => [...selectedResults, id]);
    } else if (!checked && resultIsSelected) {
      setSelectedResults(selectedResults.filter((u) => u !== id));
    }
  };

  useEffect(() => {
    getResults();
  }, []);

  const getResults = async () => {
    setIsLoading(true);
    try {
      const URL = `${JOB_URL}/${jobId}/results`;
      const { data } = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      for (const r of data) {
        getProductImage(r.product.id);
      }
      var sortedData = data.sort((a, b) => b.result.score - a.result.score);
      var benchmarkId = null;
      for (const p of sortedData) {
        if (!p.product.is_optimized) {
          benchmarkId = p.product.id;
          setMaxScore(p.result.score * SUR_CHARGE);
          break;
        }
      }
      setBenchmark(benchmarkId);
      var scores = sortedData.map((r) => r.product.id);
      setScoreIds(scores);
      setResults(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadCsv = async () => {
    setDownloadLoading(true);
    const URL = `/jobs/${jobId}/results/csv`;
    try {
      const response = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        responseType: "blob", // Specify the response type as blob
      });
      if (response.status !== 200) {
        setErr("Failed to fetch csv data");
      }
      const blob = new Blob([response.data], { type: "text/csv" });
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "results.csv";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    } finally {
      setDownloadLoading(false);
    }
  };

  const FoldCategory = ({ name, id }) => {
    return (
      <h3
        style={{ marginTop: "20px", marginBottom: "20px" }}
        onClick={() => {
          setFolded((folded) =>
            folded.map((f, i) => {
              if (i === id) {
                return !f;
              }
              return f;
            }),
          );
        }}
      >
        {name + (folded[id] ? "   ◀️" : "   🔽")}
      </h3>
    );
  };

  const functionalityFields = [
    { name: "Transport", index: "F1" },
    { name: "Opening", index: "F2" },
    { name: "Dosing", index: "F3" },
    { name: "Closing", index: "F4" },
    { name: "Storing", index: "F5" },
    { name: "Content", index: "F6" },
    { name: "Hygiene", index: "F7" },
    { name: "Residual Amount", index: "F8" },
    { name: "Safety", index: "F9" },
    { name: "Seperation by Customer", index: "F10" },
    { name: "Reusage by Customer", index: "F12" },
    { name: "Refill", index: "F13" },
    { name: "Life Prolongation", index: "F14" },
    { name: "Hygiene Seal", index: "F15" },
  ];

  const declarationFields = [
    { name: "Shelf-Life", index: "D1" },
    { name: "Usage frequency", index: "D2" },
    { name: "Add features", index: "D3" },
    { name: "Recycling Code", index: "D4" },
    { name: "Packaging Labels", index: "D5" },
    { name: "Recycling", index: "D6" },
    { name: "Instructions", index: "D7" },
    { name: "Fake Packaging", index: "D8" },
    { name: "Biodegradable", index: "D9" },
    { name: "Compostable", index: "D10" },
  ];

  const circularityFields = [
    { name: "Compliance Minimum Standard", index: "RR1" },
    { name: "LUCID Registration", index: "RR2" },
    { name: "RecyClass-Rating", index: "RR3" },
    { name: "Recyling Rate", index: "RR4" },
    { name: "Number of Components", index: "RR5" },
    { name: "Separation At Recycler", index: "RR6" },
    { name: "Number of Colors", index: "RR7" },
    { name: "Area of Colors", index: "RR8" },
    { name: "Number of Ink Properties", index: "RR9" },
    { name: "Reusage", index: "RR10" },
    { name: "Shrink Foil", index: "RR11" },
    { name: "Black Material", index: "RR12" },
    { name: "Multi-Layer", index: "RR13" },
    { name: "Label Size", index: "RR14" },
    { name: "Label Type", index: "RR15" },
    { name: "Metallisation", index: "RR16" },
    { name: "Residual Amount", index: "RR17" },
    { name: "Accidification", index: "RW1" },
    { name: "Climate Change", index: "RW2" },
    { name: "Ecotoxicity: Freshwater", index: "RW3" },
    { name: "Energy Resources Non Renewable", index: "RW4" },
    { name: "Eutrophication: Freshwater", index: "RW5" },
    { name: "Eutrophication: Marine", index: "RW6" },
    { name: "Eutrophication: Terrestrial", index: "RW7" },
    { name: "Human Toxicity Carcinogenic", index: "RW8" },
    { name: "Human Toxicity Non-Carcinogenic", index: "RW9" },
    { name: "Ionising Radiation Human Health", index: "RW10" },
    { name: "Land use", index: "RW11" },
    { name: "Material Resources", index: "RW12" },
    { name: "Ozone Depletion", index: "RW13" },
    { name: "Particulate Matter Formation", index: "RW14" },
    { name: "Photochemical Ozone Formation", index: "RW15" },
    { name: "Water use", index: "RW16" },
    { name: "Heating value", index: "RW17" },
    { name: "Littering impact", index: "RW18" },
  ];

  const materialFields = [
    { name: "Accidification", index: "ME1" },
    { name: "Climate Change", index: "ME2" },
    { name: "Ecotoxicity: Freshwater", index: "ME3" },
    { name: "Energy Resources Non Renewable", index: "ME4" },
    { name: "Eutrophication: Freshwater", index: "ME5" },
    { name: "Eutrophication: Marine", index: "ME6" },
    { name: "Eutrophication: Terrestrial", index: "ME7" },
    { name: "Human Toxicity Carcinogenic", index: "ME8" },
    { name: "Human Toxicity Non-Carcinogenic", index: "ME9" },
    { name: "Ionising Radiation Human Health", index: "ME10" },
    { name: "Land use", index: "ME11" },
    { name: "Material Resources", index: "ME12" },
    { name: "Ozone Depletion", index: "ME13" },
    { name: "Particulate Matter Formation", index: "ME14" },
    { name: "Photochemical Ozone Formation", index: "ME15" },
    { name: "Water use", index: "ME16" },
    { name: "Total Weight / Packaging Weight ratio", index: "MA1" },
    { name: "Weight-Reduction", index: "MA2" },
    { name: "Size-Reduction", index: "MA3" },
    { name: "Mehrfachverpackung", index: "MA4" },
    { name: "Typ", index: "MR1" },
    { name: "Herkunft", index: "MR2" },
    { name: "Inhalt", index: "MR3" },
    { name: "Climate Change", index: "MT1" },
    { name: "Energy Resources Non Renewable", index: "MT2" },
  ];

  const getProductImage = async (productId) => {
    const URL = `/products/${productId}/image`;
    setLoadingProductImages((prevLoadingProductImages) => {
      const updatedLoadingProductImages = new Map(prevLoadingProductImages);
      updatedLoadingProductImages.set(productId, true);
      return updatedLoadingProductImages;
    });
    await axios
      .get(URL, {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        const base64 = btoa(
          new Uint8Array(res.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            "",
          ),
        );
        setProductImages((prevProductImages) => {
          const updatedProductImages = new Map(prevProductImages);
          updatedProductImages.set(productId, `data:;base64,${base64}`);
          return updatedProductImages;
        });
      })
      .catch((error) => {
        console.log("Loading image error: " + error);
      })
      .finally(() => {
        setLoadingProductImages((prevLoadingProductImages) => {
          const updatedLoadingProductImages = new Map(prevLoadingProductImages);
          updatedLoadingProductImages.set(productId, false);
          return updatedLoadingProductImages;
        });
      });
  };

  const ResultTable = ({ fields, results, productIds }) => {
    console.log(JSON.stringify(flattenObject(results[0])));
    return Object.keys(flattenObject(results[0])).map((propName, i) => {
      return (
        <>
          <tr>
            {results.map((r, f) => {
              var isBenchmark = productIds[f] === benchmark;
              var flat = flattenObject(r);
              return (
                <td
                  scope="row"
                  style={{
                    borderTop: isBenchmark
                      ? "none"
                      : "rgba(231, 234, 243, 0.7) solid 1px",
                    borderBottom: "none",
                    backgroundColor: isBenchmark ? "#b8f5b8" : "white",
                  }}
                >
                  {f === 0 && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h5 style={{ marginTop: "10px", marginBottom: "5px" }}>
                        {fields[i].name}
                      </h5>
                      <a
                        style={{
                          marginLeft: "10px",
                          marginTop: "8px",
                          fontSize: "0.875rem",
                        }}
                      >
                        {fields[i].index}
                      </a>
                    </div>
                  )}
                </td>
              );
            })}
          </tr>
          <tr>
            {results.map((r, f) => {
              var isBenchmark = productIds[f] === benchmark;
              var flat = flattenObject(r);
              return (
                <td
                  scope="row"
                  style={{
                    borderTop: isBenchmark
                      ? "none"
                      : "rgba(231, 234, 243, 0.7) solid 1px",
                    borderBottom: "none",
                    backgroundColor: isBenchmark ? "#b8f5b8" : "white",
                  }}
                >
                  {(flat[propName] ?? 0).toFixed(3)}
                </td>
              );
            })}
          </tr>
        </>
      );
    });
  };

  const clearSelection = () => {
    setSelectedResults([]);
  };

  const Selection = () => {
    return (
      <div className="d-flex align-items-center">
        <span className="fs-5 me-3">
          <span id="datatableCounter">{selectedResults.length + " "}</span>
          selected
        </span>
        <button
          type="button"
          className="btn btn-soft-primary"
          onClick={() => setSelectionModalOpen(true)}
        >
          <i className="bi bi-copy" /> Open comparison
        </button>
        <div style={{ width: "20px" }}></div>
        {selectedResults.length > 0 && (
          <a
            className="btn btn-outline-danger btn-sm"
            href="javascript:;"
            onClick={() => clearSelection()}
          >
            <i className="bi bi-arrows-collapse-vertical" /> Clear comparison
          </a>
        )}
      </div>
    );
  };

  return (
    <>
      {selectionModalOpen && (
        <SelectedResultsModal
          handleClose={() => setSelectionModalOpen(false)}
          results={results.filter((r) =>
            selectedResults.includes(r.product.id),
          )}
          maxScore={maxScore}
          productImages={productImages}
          loadingProductImages={loadingProductImages}
          scoreIds={scoreIds}
          benchmark={benchmark}
          selectedResults={selectedResults}
          setSelectedResults={setSelectedResults}
        />
      )}
      <div style={{ width: "100%" }}>
        <div className="card">
          <div className="card-header">
            <div className="row justify-content-between align-items-center flex-grow-1">
              <div className="col-md">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="card-header-title">Results</h3>
                </div>
              </div>
              <div className="col-auto">
                <div className="row align-items-sm-center">
                  <div className="col-sm-auto">
                    {selectedResults.length > 0 && <Selection />}
                  </div>
                  <div className="col-md">
                    <button
                      type="button"
                      className="btn btn-ghost-primary"
                      onClick={() => downloadCsv()}
                    >
                      {downloadLoading ? "Downloading..." : "Download as .csv"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body" style={{ overflow: "scroll" }}>
            <LoadingAndErrIndicator
              err={err}
              isLoading={isLoading}
              loadingMessage={"Fetching results..."}
            />
            {results?.length === 0 && !isLoading && err === "" && (
              <Warning
                warning={
                  "There are no results, likely due to either missing products for this job or incomplete questionnaire submissions."
                }
              />
            )}
            {results.length > 0 && (
              <ProductResultTable
                results={results}
                maxScore={maxScore}
                productImages={productImages}
                loadingProductImages={loadingProductImages}
                scoreIds={scoreIds}
                benchmark={benchmark}
                selectedResults={selectedResults}
                setSelectedResults={setSelectedResults}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Results;
