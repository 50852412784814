import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import Loading from "../general/status/Loading";
import ProductStars from "../admin/ProductStars";
import placeholderImage from "../../assets/img/placeholder.jpg";
import ProductResultTable from "./ProductResultTable";
import LoadingAndErrIndicator from "../general/status/LoadingAndErrorIndicator";
import SelectedResultsModal from "./SelectedResultsModal";
import Warning from "../general/status/Warning";
import ComparisonTable from "./ComparisonTable";
import SelectedComparisonModal from "./SelectedComparisonModal";

const JOB_URL = "/jobs";

const Comparison = () => {
  const { jobId } = useParams();
  const { auth } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [err, setErr] = useState("");
  const [results, setResults] = useState([]);
  const [selectedResults, setSelectedResults] = useState([]);
  const [productImages, setProductImages] = useState(new Map());
  const [loadingProductImages, setLoadingProductImages] = useState(new Map());
  const [selectionModalOpen, setSelectionModalOpen] = useState(false);

  useEffect(() => {
    getResults();
  }, [jobId]);

  const getResults = async () => {
    setIsLoading(true);
    try {
      const URL = `${JOB_URL}/${jobId}/compare/check`;
      const { data } = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      for (const r of data.product_results) {
        getProductImage(r.info.id);
      }
      setResults(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getProductImage = async (productId) => {
    const URL = `/products/${productId}/image`;
    setLoadingProductImages((prevLoadingProductImages) => {
      const updatedLoadingProductImages = new Map(prevLoadingProductImages);
      updatedLoadingProductImages.set(productId, true);
      return updatedLoadingProductImages;
    });
    await axios
      .get(URL, {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        const base64 = btoa(
          new Uint8Array(res.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            "",
          ),
        );
        setProductImages((prevProductImages) => {
          const updatedProductImages = new Map(prevProductImages);
          updatedProductImages.set(productId, `data:;base64,${base64}`);
          return updatedProductImages;
        });
      })
      .catch((error) => {
        console.log("Loading image error: " + error);
      })
      .finally(() => {
        setLoadingProductImages((prevLoadingProductImages) => {
          const updatedLoadingProductImages = new Map(prevLoadingProductImages);
          updatedLoadingProductImages.set(productId, false);
          return updatedLoadingProductImages;
        });
      });
  };

  const clearSelection = () => {
    setSelectedResults([]);
  };

  const Selection = () => {
    return (
      <div className="d-flex align-items-center">
        <span className="fs-5 me-3">
          <span id="datatableCounter">{selectedResults.length + " "}</span>
          selected
        </span>
        <button
          type="button"
          className="btn btn-soft-primary"
          onClick={() => setSelectionModalOpen(true)}
        >
          <i className="bi bi-copy" /> Open comparison
        </button>
        <div style={{ width: "20px" }}></div>
        {selectedResults.length > 0 && (
          <a
            className="btn btn-outline-danger btn-sm"
            href="javascript:;"
            onClick={() => clearSelection()}
          >
            <i className="bi bi-arrows-collapse-vertical" /> Clear comparison
          </a>
        )}
      </div>
    );
  };

  return (
    <>
      {/*selectionModalOpen && (
        <SelectedComparisonModal
          handleClose={() => setSelectionModalOpen(false)}
          results={{
            ...results,
            product_results: results.product_results.filter((p) =>
              selectedResults.includes(p.info.id),
            ),
          }}
          productImages={productImages}
          loadingProductImages={loadingProductImages}
          selectedResults={selectedResults}
          setSelectedResults={setSelectedResults}
        />
      )*/}
      <div style={{ width: "100%" }}>
        <div className="card">
          <div className="card-header">
            <div className="row justify-content-between align-items-center flex-grow-1">
              <div className="col-md">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="card-header-title">Results</h3>
                </div>
              </div>
              <div className="col-auto">
                <div className="row align-items-sm-center">
                  <div className="col-sm-auto">
                    {selectedResults.length > 0 && <Selection />}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body" style={{ overflow: "scroll" }}>
            <LoadingAndErrIndicator
              err={err}
              isLoading={isLoading}
              loadingMessage={"Fetching results..."}
            />
            {results?.product_results?.length === 0 &&
              !isLoading &&
              err === "" && (
                <Warning
                  warning={
                    "There are no results, likely due to either missing products for this job or incomplete questionnaire submissions."
                  }
                />
              )}
            {results.product_results?.length > 0 && (
              <ComparisonTable
                results={results}
                productImages={productImages}
                loadingProductImages={loadingProductImages}
                selectedResults={selectedResults}
                setSelectedResults={setSelectedResults}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Comparison;
