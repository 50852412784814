import { useParams } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import LabForm from "./lab/LabForm";
import ProductUsers from "./users/ProductUsers";
import ComponentsForm from "./ComponentsForm";
import Result from "./Result";
import placeholderImage from "../../../assets/img/placeholder.jpg";
import moment from "moment";
import { Link } from "react-router-dom";
import Error from "../../general/status/Error";
import Lab from "./lab/Lab";
import { ROLES } from "../../../App";
import TabView from "../../general/TabView";
import ProductForm from "./ProductForm";
import ProductInfo from "./ProductInfo";
import { VERSIONS } from "./ProductInfo";
import Efa from "./Efa";
import Loading from "../../general/status/Loading";
import QuickCheck from "./QuickCheck";

const Product = ({ role }) => {
  const [algoVersion, setAlgoVersion] = useState();

  const getTabsByRole = () => {
    const commonTabs = [
      {
        index: 0,
        name: "Company",
        tab: <ProductForm algoVersion={algoVersion} />,
      },
      {
        index: 1,
        name: "Components",
        tab: <ComponentsForm algoVersion={algoVersion} />,
      },
    ];

    switch (role) {
      case ROLES.Admin:
        return [
          ...commonTabs,
          { index: 2, name: "Lab", tab: <Lab algoVersion={algoVersion} /> },
          // { index: 3, name: "Users", tab: <ProductUsers /> },
          {
            index: 3,
            name: "SUSYinventory",
            tab: <QuickCheck />,
          },
          {
            index: 4,
            name: "Score",
            tab: <Result algoVersion={algoVersion} />,
          },
          algoVersion !== VERSIONS[0]
            ? {
                index: 5,
                name: "EFA",
                tab: <Efa />,
              }
            : null,
        ].filter(Boolean);
      case ROLES.Laboratory:
        return [
          ...commonTabs,
          { index: 2, name: "Lab", tab: <LabForm algoVersion={algoVersion} /> },
        ];
      case ROLES.Customer:
        return commonTabs;
      default:
        return [];
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <ProductInfo setAlgoVersion={setAlgoVersion} />
      {algoVersion ? (
        <>
          <TabView tabs={getTabsByRole()} />
        </>
      ) : (
        <Loading loadingMessage={"Fetching product info..."} />
      )}
    </div>
  );
};

export default Product;
