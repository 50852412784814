import React, { useContext } from "react";
import { FormContext } from "../../general/FormContext";
const Select = ({ rowId, id, label, placeholder, value, options }) => {
  const { handleChange } = useContext(FormContext);
  return (
    <>
      <select
        className="form-select"
        aria-label=""
        value={value}
        onChange={(event) => handleChange(rowId, id, event)}
        style={{ minWidth: "200px" }}
      >
        <option value={1337}>
          {placeholder ?? "Please select an option..."}
        </option>
        {options.length > 0 &&
          options.map((option, i) => (
            <option value={option.id} key={i}>
              {option.name}
            </option>
          ))}
      </select>
    </>
  );
};

export default Select;
