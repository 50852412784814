import { useContext, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import ProductTable from "../products/ProductTable";
import Results from "./Results";
import GhostError from "../general/status/GhostError";
import TabView from "../general/TabView";
import JobInfo from "./JobInfo";
import QuickChecks from "./QuickChecks";
import Comparison from "./Comparison";

const JOB_URL = "/jobs";
const Job = () => {
  const { jobId } = useParams();
  const { auth } = useAuth();

  //const [isAssignLabModalOpen, setIsAssignLabModalOpen] = useState(false);
  const tabs = [
    { index: 0, name: "Products", tab: <ProductTable jobId={jobId} /> },
    //{ index: 1, name: "SUSYcheck", tab: <Results /> },
    { index: 1, name: "SUSYcheck", tab: <Comparison /> },
    { index: 2, name: "SUSYinventory", tab: <QuickChecks /> },
  ];

  return (
    <div style={{ width: "100%" }}>
      <JobInfo jobId={jobId} />
      <TabView tabs={tabs} />
    </div>
  );
};

export default Job;
