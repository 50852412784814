import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import Loading from "../general/status/Loading";
import GhostError from "../general/status/GhostError";
import Modal from "../general/Modal";

const NEW_JOB_URL = "/jobs/new";

const AddJobModal = ({ handleClose }) => {
  const { auth } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState("");
  const [jobName, setJobName] = useState("");
  const navigate = useNavigate();

  const createJob = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(
        NEW_JOB_URL,
        {
          name: jobName,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
      );
      navigate(`./jobs/${data.id}`);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal title={"Create Job"} handleClose={handleClose}>
      <div className="modal-body">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            createJob();
          }}
        >
          <div>
            <p>Create a new job with the following name</p>
            <label className="form-label" htmlFor="signinSrEmail">
              Name
            </label>
            <input
              type="text"
              key="lab-name"
              className="form-control form-control-lg"
              name="lab-name"
              id="signinSrEmail"
              onChange={(e) => setJobName(e.target.value)}
              tabIndex={1}
              value={jobName}
              placeholder=""
              aria-label="00000"
              required=""
            />
          </div>
        </form>
        {isLoading ? (
          <Loading desc={"Creating new job"} />
        ) : err ? (
          <GhostError err={err} />
        ) : (
          <></>
        )}
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={handleClose}
          className="btn btn-white"
          data-bs-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          onClick={() => createJob()}
          className="btn btn-primary"
        >
          Create Job
        </button>
      </div>
    </Modal>
  );
};

export default AddJobModal;
