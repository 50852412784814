import { useState } from 'react';


const PasswordField = ({ value, onChange }) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <div
            className="input-group input-group-merge"
            data-hs-validation-validate-className=""
        >
            <input
                type={isVisible ? "text" : "password"}
                className="js-toggle-password form-control form-control-lg"
                name="password"
                id="signupSrPassword"
                placeholder=""
                aria-label=""
                required=""
                minLength={8}
                onChange={(e) => onChange(e.target.value)}
                value={value}
                data-hs-toggle-password-options='{
                     "target": "#changePassTarget",
                     "defaultClass": "bi-eye-slash",
                     "showClass": "bi-eye",
                     "classChangeTarget": "#changePassIcon"
                   }'
            />
            <a
                id="changePassTarget"
                className="input-group-append input-group-text"
                href="javascript:;"
                onClick={() => setIsVisible(!isVisible)}
            >
                <i id="changePassIcon" className={isVisible ? "bi-eye-slash" : "bi-eye"} />
            </a>
        </div>
    );
}


export default PasswordField;