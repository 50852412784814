import { useContext, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import useAuth from "../../../../hooks/useAuth";
import axios from "../../../../api/axios";
import Loading from "../../../general/status/Loading";
import Error from "../../../general/status/Error";
import { ROLES } from "../../../../App";

const ASSIGN_LAB_URL = '/admin/assign-lab';
const LABS_URL = '/admin/labs';

const AssignLabModal = ({ handleClose, jobId }) => {
  const { auth } = useAuth();

  const [invitationData, setInvitationData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState('');
  const [copied, setCopied] = useState(false);
  const [role, setRole] = useState(0);
  const [labData, setLabData] = useState([]);
  const [lab, setLab] = useState(null);


  const assignLab = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(
        ASSIGN_LAB_URL,
        {
            jobId: parseInt(jobId),
            labId: parseInt(lab),
        },
        {
          headers: {
            'Authorization': `Bearer ${auth.accessToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );
      setInvitationData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getLabs();
  }, []);

  const getLabs = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        LABS_URL,
        {
            headers: {
                'Authorization': `Bearer ${auth.accessToken}`,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        },
    );
      setLabData(data);
      setLab(data[0]?.id);
    } catch (err) {
        setErr(err.message);
    } finally {
        setIsLoading(false);
    }
  }


  const LabSelector = () => {
    return (          
      <div className="mb-4">
        <label className="form-label" htmlFor="signinSrEmail">
          Laborzuweisung
        </label>
          {labData.length < 1 ? (<a className="text-danger">Es existieren keine Labore</a>) : (
          <select className="form-select" onChange={event => setLab(event.target.value)} defaultValue={lab}>
            {labData.map((lab) => <option value={lab.id}>{lab.name}</option>)}
          </select>
        )}
      </div>
    );
  }

  return (
    <div id="exampleModalCenter" className="modal fade show" tabindex="-1" aria-labelledby="exampleModalCenterTitle" style={{display: "block", paddingLeft: "0px", backgroundColor: "rgba(0,0,0,0.4)"}} aria-modal="true" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="display-5" id="exampleModalCenterTitle">
              Assign Lab
            </h5>
            <button
              onClick={handleClose}
              style={{marginTop: "-30px"}}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <LabSelector/>

            {(isLoading || err) && (isLoading ? <Loading /> : (err ? <Error err={err} /> : <></>))}
          </div>
          <div className="modal-footer">
            <button type="button" onClick={handleClose} className="btn btn-white" data-bs-dismiss="modal">
              Close
            </button>
            <button type="button" onClick={() => assignLab()} className="btn btn-primary">
              Labor zuweisen
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssignLabModal;