import { useParams } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import useAuth from "../../../../hooks/useAuth";
import axios from "../../../../api/axios";
import { FormContext } from "../../../general/FormContext";
import FormElement from "../../../form_elements/FormElement";
import LoadingAndErrIndicator from "../../../general/status/LoadingAndErrorIndicator";

const PRODUCT_URL = "/lab/products";
const UPDATE_LAB_PRODUCT_URL = "lab/products/update";

const LabForm = ({ algoVersion }) => {
  const { productId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { auth } = useAuth();
  const [err, setErr] = useState("");
  const [productFields, setProductFields] = useState([]);

  useEffect(() => {
    getProduct();
  }, [productId, algoVersion]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let fields = productFields
      .filter((field) => field.ty !== "Headline")
      .map((field) => {
        if ("TextInput" in field.ty) {
          return { id: field.id, value: field.ty.TextInput.value };
        }
        if ("CheckBox" in field.ty) {
          return { id: field.id, value: field.ty.CheckBox.value };
        }
        if ("Select" in field.ty) {
          return {
            id: field.id,
            value:
              field.ty.Select.value !== 1337
                ? `${field.ty.Select.value}`
                : null,
          };
        }
        return null;
      });
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `${UPDATE_LAB_PRODUCT_URL}/${algoVersion.toLowerCase()}`,
        {
          productId: parseInt(productId),
          fields: fields,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
      );
      setProductFields(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (field_id, event) => {
    const newFields = [...productFields];
    newFields.forEach((field) => {
      const { ty, id } = field;
      if (id === field_id) {
        if ("CheckBox" in ty) {
          field.ty.CheckBox.value = event.target.checked;
        } else if ("TextInput" in ty) {
          field.ty.TextInput.value = event.target.value;
        } else if ("Select" in ty) {
          field.ty.Select.value = event.target.value;
        }
      }
      setProductFields(newFields);
    });
  };

  const getProduct = async () => {
    setIsLoading(true);
    const URL = `${PRODUCT_URL}/${productId}/${algoVersion.toLowerCase()}`;
    try {
      const { data } = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setProductFields(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="card">
        <div className="card-header">
          <h3 className="card-header-title">Lab Questionnaire</h3>
        </div>
        <FormContext.Provider value={{ handleChange }}>
          <div className="card-body">
            {err || isLoading ? (
              <LoadingAndErrIndicator
                err={err}
                isLoading={isLoading}
                loadingMessage={"Fetching Lab Questionnaire..."}
              />
            ) : (
              <form>
                <div style={{ marginTop: "-45px" }}>
                  {productFields
                    ? productFields.map((field, i) => (
                        <FormElement key={i} field={field} />
                      ))
                    : null}
                </div>
              </form>
            )}
          </div>
          <div className="card-footer">
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={(e) => handleSubmit(e)}
              >
                Save changes
              </button>
            </div>
          </div>
        </FormContext.Provider>
      </div>
    </div>
  );
};

export default LabForm;
